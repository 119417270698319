import Icon from '@xo-union/tk-component-icons';
import React from 'react';
import Styles from './styles.scss';

interface CouplesUsuallySpendEmptyDetailsProps {
	couplesUsuallySpend: string | null;
}

export const CouplesUsuallySpendEmptyDetails = ({
	couplesUsuallySpend,
}: CouplesUsuallySpendEmptyDetailsProps) => {
	return (
		<div className={Styles.usuallySpend}>
			<Icon name="guest_couple" size="md" data-testid="cus-guest-couple" />
			<span>Couples usually spend {couplesUsuallySpend}</span>
		</div>
	);
};
