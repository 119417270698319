import { CouplesUsuallySpendEmptyDetails } from '@components/couples-usually-spend/CouplesUsuallySpendEmptyDetails';
import { Packages } from '@components/detailed-pricing/Packages';
import { PricingCta } from '@components/detailed-pricing/PricingCta';
import { usePdfUrl } from '@components/pricing/hooks/use-pdf-url';
import { PricingPdf } from '@components/pricing/pdf';
import { CUSSecondaryCta } from '@components/pricing/pricing-details/CUSSecondaryCta';
import { CATEGORY_SINGULAR_ABBREVIATED_TERMS } from '@constants/categories';
import { useStorefrontCouplesUsuallySpend } from '@feature-flags/hooks/Storefront/useStorefrontCouplesUsuallySpend';
import { useStorefrontEmptyStates } from '@feature-flags/hooks/Storefront/useStorefrontEmptyStates';
import { useStorefrontFeatureBvcPlusPricing } from '@feature-flags/hooks/Storefront/useStorefrontFeatureBvcPlusPricing';
import { useTrackStorefrontImpression } from '@hooks/useTrackStorefrontImpression';
import { useAppSelector } from '@redux/hooks';
import Icon from '@xo-union/tk-component-icons';
import { Body1, H3 } from '@xo-union/tk-ui-typography';
import { useAtomValue } from 'jotai';
import React, { type FC } from 'react';
import type { CategoryCode } from 'types/Category';
import {
	detailedPricingUsuallySpendAtom,
	packagesAtom,
} from '../../jotai/storefront/detailed-pricing';
import { EmptyStateSearchLink } from '../../pages/Storefront/components/EmptyStateSearchLink/EmptyStateSearchLink';
import type { NavItemProps } from '../../pages/Storefront/containers/nav/types';
import Styles from './package-pricing.scss';

const NoPricingDetails = () => {
	const isEmptyStates = useStorefrontEmptyStates();
	const category = useAppSelector(
		(state) => state.vendor.vendor?.categoryCode as CategoryCode,
	);

	if (isEmptyStates) {
		return (
			<EmptyStateSearchLink
				header="No pricing details yet"
				text={
					category === 'WPH'
						? 'Looking for the perfect match?'
						: 'We’re here to help! Find other vendors that suit your budget.'
				}
				linkText="Continue browsing vendors"
				storefrontSection="Packages"
				inline={category === 'WPH'}
			/>
		);
	}

	return (
		<div className={Styles.noPricingDetailsWrapper}>
			<div className={Styles.noPricingDetails}>
				<Body1 bold>No pricing details yet</Body1>
				<Body1>Reach out to get a customized quote from this vendor.</Body1>
			</div>
			<PricingCta sourceContent="Packages" />
		</div>
	);
};

const PricingDetails = () => {
	const isBvcPlusDetailedPricing = useStorefrontFeatureBvcPlusPricing();
	const categoryCode = useAppSelector(
		(state) => state.vendor.vendor?.categoryCode as CategoryCode,
	);
	const singularTerm = CATEGORY_SINGULAR_ABBREVIATED_TERMS[categoryCode];
	const pricingDetailsText = `Starting prices may not include all fees a vendor may charge. Contact the ${singularTerm} for customized services and quotes.`;
	const pdfText =
		'See all the vendor’s pricing and services in this helpful guide.';

	return (
		<div className={Styles.pdfCtaWrapper}>
			{!isBvcPlusDetailedPricing && (
				<PricingPdf text={pdfText}>
					<div className={Styles.divider} />
				</PricingPdf>
			)}
			<div className={Styles.iconWrapper}>
				<Icon size="sm" name="info" />
				{pricingDetailsText}
			</div>
			<PricingCta sourceContent="Packages" />
		</div>
	);
};

export const CouplesUsuallySpend = ({
	hasPackages,
}: { hasPackages: boolean }) => {
	const couplesUsuallySpend = useAtomValue(detailedPricingUsuallySpendAtom);

	if (!hasPackages) {
		return (
			<CouplesUsuallySpendEmptyDetails
				couplesUsuallySpend={couplesUsuallySpend}
			/>
		);
	}

	return (
		<ul className={Styles.usuallySpendDisclaimerList}>
			<li>Starting prices may not include all fees a vendor may charge.</li>
			{couplesUsuallySpend && (
				<li>Couples usually spend {couplesUsuallySpend}</li>
			)}
		</ul>
	);
};

interface AdditionalPricingDetailsProps {
	shouldShowCUS: boolean;
	hasPricingDetails: boolean;
}

const AdditionalPricingDetails = ({
	shouldShowCUS,
	hasPricingDetails,
}: AdditionalPricingDetailsProps) => {
	const couplesUsuallySpend = useAtomValue(detailedPricingUsuallySpendAtom);

	if (shouldShowCUS) {
		return <CUSSecondaryCta couplesUsuallySpend={couplesUsuallySpend} />;
	}

	return hasPricingDetails ? <PricingDetails /> : <NoPricingDetails />;
};

export const PackagePricing: FC<NavItemProps> = () => {
	const isBvcPlusDetailedPricing = useStorefrontFeatureBvcPlusPricing();
	const shouldShowCUS = useStorefrontCouplesUsuallySpend();
	const packages = useAtomValue(packagesAtom);
	const pdfUrl = usePdfUrl();
	const hasPricingDetails = Boolean(
		isBvcPlusDetailedPricing
			? packages.length
			: packages.length || pdfUrl?.length,
	);
	const milestone = isBvcPlusDetailedPricing
		? 'Detailed Pricing Packages'
		: 'Packages';

	const trackingRef = useTrackStorefrontImpression(milestone);

	return (
		<div className={Styles.pricesAndPackages}>
			<H3 ref={trackingRef}>Prices & packages</H3>
			<Packages />
			{shouldShowCUS && (
				<CouplesUsuallySpend hasPackages={Boolean(packages.length)} />
			)}
			<AdditionalPricingDetails
				shouldShowCUS={shouldShowCUS}
				hasPricingDetails={hasPricingDetails}
			/>
		</div>
	);
};
