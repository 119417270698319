import Icon from '@xo-union/tk-component-icons';
import { Body1 } from '@xo-union/tk-ui-typography';
import React, { type FC } from 'react';
import Styles from './Faq.scss';

interface Props {
	text: 'Reception' | 'Ceremony' | 'Bar services' | 'Catering';
	value: string | null;
	iconName: 'category-rec' | 'category-cer' | 'category-bar' | 'category-cat';
}

export const Faq: FC<Props> = ({ text, iconName, value }) => {
	return (
		<div className={Styles.faq}>
			<div className={Styles.circle}>
				<Icon name={iconName} size="lg" />
			</div>
			<div className={Styles.details}>
				<Body1>{text}:</Body1>
				<Body1 bold>{value}</Body1>
			</div>
		</div>
	);
};
