import { CouplesUsuallySpendEmptyDetails } from '@components/couples-usually-spend/CouplesUsuallySpendEmptyDetails';
import { Column, Row } from '@xo-union/tk-component-grid';
import Icon from '@xo-union/tk-component-icons';
import { Body1, Editorial } from '@xo-union/tk-ui-typography';
import { useAtomValue } from 'jotai';
import React from 'react';
import {
	couplesUsuallySpendAtom,
	formattedPricingDetailsAtom,
	hasPricingDetailsAtom,
	pricingDetailsAtom,
} from '../../../jotai/storefront/pricing';
import { CUSSecondaryCta } from './CUSSecondaryCta';
import Styles from './PricingDetails.scss';

type FAQProps = {
	className: string;
	icon: string;
	text: string;
	value: string;
};

const FAQ = ({ className, icon, text, value }: FAQProps) => {
	return (
		<div className={className}>
			<Icon name={icon} size="md" />
			<div className={Styles.text}>
				<Body1 className={Styles.faqTitle}>{text}</Body1>
				<Body1 bold>{value}</Body1>
			</div>
		</div>
	);
};

const CouplesUsuallySpend = () => {
	const usuallySpend = useAtomValue(couplesUsuallySpendAtom);
	const { months } = useAtomValue(pricingDetailsAtom);

	return (
		<ul className={Styles.usuallySpendDisclaimerList}>
			{usuallySpend && <li>Couples usually spend {usuallySpend}</li>}
			<li>
				Starting prices don't include service fees, taxes, gratuity, and rental
				fees.
			</li>
			<li>
				Guest count and seasonality may also affect prices.
				{months && ` Peak season for this venue is ${months}.`}
			</li>
		</ul>
	);
};

export const CUSPricingDetails = () => {
	const pricingDetails = useAtomValue(formattedPricingDetailsAtom);
	const hasPricingDetails = useAtomValue(hasPricingDetailsAtom);
	const couplesUsuallySpend = useAtomValue(couplesUsuallySpendAtom);

	return hasPricingDetails ? (
		<>
			<div className={Styles.pricingDetails}>
				<Editorial bold>Starting prices</Editorial>
				<Row>
					<Column md="4" className={Styles.receptionFaqDesktop}>
						<div className={Styles.circle}>
							<Icon name="category-rec" size="lg" />
						</div>
						<Body1>Reception</Body1>
						<Body1 bold>{pricingDetails.reception}</Body1>
					</Column>
					<Column md="8">
						<FAQ
							className={Styles.faqContainerReception}
							icon="category-rec"
							text="Reception"
							value={pricingDetails.reception}
						/>
						<FAQ
							className={Styles.faqContainer}
							icon="category-cer"
							text="Ceremony"
							value={pricingDetails.ceremony}
						/>
						<FAQ
							className={Styles.faqContainer}
							icon="category-bar"
							text="Bar services"
							value={pricingDetails.barService}
						/>
						<FAQ
							className={Styles.faqContainer}
							icon="category-cat"
							text="Catering"
							value="Contact for price"
						/>
					</Column>
				</Row>
			</div>
			<CouplesUsuallySpend />
			<CUSSecondaryCta couplesUsuallySpend={couplesUsuallySpend} />
		</>
	) : (
		<>
			<CouplesUsuallySpendEmptyDetails
				couplesUsuallySpend={couplesUsuallySpend}
			/>
			<CUSSecondaryCta couplesUsuallySpend={couplesUsuallySpend} />
		</>
	);
};
